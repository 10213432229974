import React, { useEffect, useState, useRef } from "react";
import MeetPage from "./MeetPage";
import Sidebar from "components/Elements/sidebar";
import Cropper from "react-easy-crop";
import config from "config";
import AnalyzePopup from "./AnalyzePopup";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const AgentMeetPage = () => {
  const [customerData, setCustomerData] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [cropData, setCropData] = useState(null);
  const [cropType, setCropType] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropArea, setCropArea] = useState(null);
  const navigate = useNavigate();
  const [capturedImages, setCapturedImages] = useState({
    aadhaar_photo: null,
    pan_photo: null,
    signature_photo: null,
    customer_photo: null,
  });

  const [isAnalyzePopupOpen, setIsAnalyzePopupOpen] = useState(false); // Analyze popup control
  const [isCompletePopupOpen, setIsCompletePopupOpen] = useState(false); // Complete KYC popup control
  const [isAnalyzeComplete, setIsAnalyzeComplete] = useState(false); // Check if all documents are analyzed
  const [reviewStatus, setReviewStatus] = useState(""); // Agent's review
  const [approvalStatus, setApprovalStatus] = useState(""); // Approve/Reject dropdown value
  const screenStreamRef = useRef(null); // To store the media stream
  const [analyzedStates, setAnalyzedStates] = useState({});
  const [rejectRemark, setRejectRemark] = useState("");
  const [showRemarkModal, setShowRemarkModal] = useState(false);
  const [currentRejectDoc, setCurrentRejectDoc] = useState("");
  const [isDataSent, setIsDataSent] = useState(false); // New state to track if data is sent

  const areAllImagesCaptured = () => {
    return Object.values(capturedImages).every((image) => image !== null);
  };
  // Extract Lead ID from the URL
  const currentUrl = window.location.href;
  const pathParts = new URL(currentUrl).pathname.split("/");
  const leadPart = pathParts.find((part) => part.startsWith("room-"));
  const leadId = leadPart ? leadPart.split("-")[1] : null;

  const [livePreview, setLivePreview] = useState(null);

  const generateLivePreview = (imageSrc, croppedAreaPixels) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      const preview = canvas.toDataURL("image/jpeg");
      setLivePreview(preview);
    };
  };

  // Fetch Customer Details from API
  useEffect(() => {
    const fetchCustomerDetails = async () => {
      const accessToken = sessionStorage.getItem("accessToken");
      console.log("Access Token:", accessToken);

      try {
        const response = await fetch(
          `${config.apiBaseUrl}/api/customer-details/${leadId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch customer data");
        }

        const data = await response.json();
        setCustomerData(data.data);
        const initialState = {};
        [
          "aadhaar_photo",
          "pan_photo",
          "customer_photo",
          "signature_photo",
        ].forEach((type) => {
          initialState[type] = false;
        });
        setAnalyzedStates(initialState);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    };

    if (leadId) fetchCustomerDetails();
  }, [leadId]);

  // Handle Screen Capture
  const handleScreenCapture = async (type) => {
    setCropType(type);

    try {
      let stream = screenStreamRef.current;
      if (!stream) {
        // Request permission and create a new stream if not available
        stream = await navigator.mediaDevices.getDisplayMedia({
          video: { cursor: "always" },
          audio: false,
        });
        screenStreamRef.current = stream; // Save the stream for reuse
      }

      const video = document.createElement("video");
      video.srcObject = stream;
      video.play();

      await new Promise((resolve) => (video.onloadedmetadata = resolve));

      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const image = canvas.toDataURL("image/jpeg");
      setCropData(image);
      setIsCropping(true);

      // Do not stop the tracks here. Keep the stream active for subsequent captures.
    } catch (error) {
      console.error("Error capturing screen:", error);
    }
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log("Cropped Area Pixels:", croppedAreaPixels);
    setCropArea(croppedAreaPixels); // Save the cropped area pixels
  };

  const saveCroppedImage = async () => {
    if (!cropData || !cropArea) {
      console.error("Crop data or crop area is missing.");
      return;
    }

    try {
      const image = new Image();
      image.src = cropData;

      await new Promise((resolve) => {
        image.onload = resolve;
      });

      const canvas = document.createElement("canvas");
      canvas.width = cropArea.width;
      canvas.height = cropArea.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        cropArea.x,
        cropArea.y,
        cropArea.width,
        cropArea.height,
        0,
        0,
        cropArea.width,
        cropArea.height
      );

      const croppedImage = canvas.toDataURL("image/jpeg");

      setCapturedImages((prevState) => ({
        ...prevState,
        [`${cropType.toLowerCase()}_photo`]: croppedImage,
      }));

      setIsCropping(false);
      setCropData(null);
      setCropArea(null);
    } catch (error) {
      console.error("Error saving cropped image:", error);
    }
  };

  const sendAllData = async () => {
    const formData = new FormData();
    formData.append("lead_id", leadId);

    Object.keys(capturedImages).forEach((key) => {
      if (capturedImages[key]) {
        const base64Data = capturedImages[key].split(",")[1];
        const binaryData = atob(base64Data);
        const arrayBuffer = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          arrayBuffer[i] = binaryData.charCodeAt(i);
        }
        const file = new File([arrayBuffer], `${key}.jpg`, {
          type: "image/jpeg",
        });
        formData.append(key, file);
      }
    });

    try {
      const accessToken = sessionStorage.getItem("accessToken");
      const response = await fetch(
        `${config.apiBaseUrl}/api/store-captured-document`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload images");
      }

      const responseData = await response.json();
      console.log("Images uploaded successfully:", responseData);
      setIsDataSent(true);
    } catch (error) {
      console.error("Error uploading images:", error);
      setIsDataSent(false); // Ensure flag remains false if upload fails
    }
  };

  const handleCompleteKYC = async () => {
    console.log("Review Status:", reviewStatus);
    console.log("Approval Status:", approvalStatus);

    if (!approvalStatus) {
      alert("Please select an approval status before completing VKYC.");
      return;
    }

    try {
      const payload = {
        lead_id: leadId,
        vkyc_final_review: reviewStatus,
        vkyc_final_status: approvalStatus,
      };

      const accessToken = sessionStorage.getItem("accessToken");
      const response = await fetch(`${config.apiBaseUrl}/api/final-review`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to complete VKYC");
      }

      const responseData = await response.json();
      console.log("VKYC Completion Response:", responseData);

      alert(responseData.message || "VKYC completed successfully!");
      setIsCompletePopupOpen(false);
      navigate("/bank-user-dashboard");
    } catch (error) {
      console.error("Error completing VKYC:", error);
      alert("Failed to complete VKYC. Please try again.");
    }
  };

  const documents = [
    {
      type: "Uploaded Documents",
      data: [
        { label: "Aadhaar Photo", url: customerData?.aadhaar_photo },
        { label: "Pan Photo", url: customerData?.pan_photo },
        { label: "Customer Photo", url: customerData?.customer_photo },
        { label: "Signature Photo", url: customerData?.signature_photo },
      ],
    },
    {
      type: "Captured Documents",
      data: [
        {
          label: "Aadhaar Photo (Captured)",
          url: customerData?.captured_aadhaar_photo,
        },
        {
          label: "Pan Photo (Captured)",
          url: customerData?.captured_pan_photo,
        },
        {
          label: "Customer Photo (Captured)",
          url: customerData?.captured_customer_photo,
        },
        {
          label: "Signature Photo (Captured)",
          url: customerData?.captured_signature_photo,
        },
      ],
    },
  ];

  const handleAnalyze = (type) => {
    setAnalyzedStates((prevState) => ({
      ...prevState,
      [type]: "Analyzed", // Set status as 'Analyzed'
    }));
  };

  const handleAccept = (type) => {
    setAnalyzedStates((prevState) => ({
      ...prevState,
      [type]: "Accepted", // Set status as 'Accepted'
    }));
  };

  const handleReject = (type) => {
    setCurrentRejectDoc(type);
    setShowRemarkModal(true);
  };

  const submitReject = (type) => {
    if (!rejectRemark.trim()) {
      alert("Remark is required to reject the document.");
      return;
    }

    setAnalyzedStates((prevState) => ({
      ...prevState,
      [type]: "Rejected", // Update the status to 'Rejected'
    }));

    console.log(`${type} rejected with remark: ${rejectRemark}`);
    setShowRemarkModal(false);
    setRejectRemark("");
  };
  const handleAnalyzeService = async (
    docLabel,
    uploadedUrl,
    capturedBase64
  ) => {
    try {
      // Convert captured (Base64) to file
      const capturedFile = base64ToFile(
        capturedBase64,
        `${docLabel}_captured.jpg`
      );

      // Convert uploaded (URL) to file

      const formData = new FormData();
      formData.append("label", docLabel);
      formData.append("uploaded", uploadedUrl);
      formData.append("captured", capturedFile);

      const response = await fetch(
        "https://vkyc-back.prepstripe.com/api/analyze",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      console.log("Analysis Result:", data);
      return data; // Example: { score: <calculated_score> }
    } catch (error) {
      console.error("Error analyzing document:", error);
      throw error;
    }
  };

  const base64ToFile = (base64, filename) => {
    const base64ContentArray = base64.split(",");
    if (base64ContentArray.length !== 2) {
      throw new Error("Invalid Base64 string");
    }
    const byteString = atob(base64ContentArray[1]);
    const mimeString = base64ContentArray[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ab], filename, { type: mimeString });
  };
  const urlToFile = async (url, filename) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch image from URL: ${url}`);
    }
    const blob = await response.blob();
    return new File([blob], filename, { type: blob.type });
  };

  return (
    <div className="h-screen flex">
      {/* Sidebar (30%) */}
      <Sidebar customerData={customerData} />

      {/* Main Content (70%) */}
      <div className="w-9/12 flex flex-col">
        {/* Upper Section */}
        <div
          className="flex-grow bg-gray-100"
          style={{ flexBasis: "70%", overflow: "hidden" }}
        >
          <MeetPage userType="agent" />
        </div>

        {/* Lower Section */}
        <div className="bg-gray-100 pt-4 pr-4" style={{ flexBasis: "30%" }}>
          <div className="grid grid-cols-4 gap-4">
            {["Aadhaar", "PAN", "Signature", "Customer"].map((type) => (
              <div
                key={type}
                className="border rounded shadow-lg p-4 h-full flex flex-col justify-between items-center"
              >
                <h3 className="text-md font-semibold mb-2">{type}</h3>
                {capturedImages[`${type.toLowerCase()}_photo`] ? (
                  <img
                    src={capturedImages[`${type.toLowerCase()}_photo`]}
                    alt={`${type} Snapshot`}
                    className="w-32 h-32 object-cover"
                  />
                ) : (
                  <button
                    onClick={() => handleScreenCapture(type)}
                    className="btn-sm w-32 py-2 text-sm text-white bg-blue-500 hover:bg-blue-600 rounded-full shadow-md"
                  >
                    Capture
                  </button>
                )}
              </div>
            ))}
          </div>

          {/* Buttons */}
          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={sendAllData}
              className={`px-4 py-2 rounded-md shadow-md ${
                areAllImagesCaptured() && !isDataSent
                  ? "bg-green-500 text-white"
                  : "bg-gray-400 text-gray-600 cursor-not-allowed"
              }`}
              disabled={!areAllImagesCaptured() || isDataSent} // Disable if not all images are captured or data is already sent
              title={
                isDataSent
                  ? "Data has already been sent!"
                  : "Click to send data"
              } // Tooltip message
            >
              {isDataSent ? "Data Sent" : "Send Data"}
            </button>

            <button
              onClick={() => setIsAnalyzePopupOpen(true)}
              className={`px-4 py-2 rounded-md shadow-md ${
                isAnalyzeComplete
                  ? "bg-gray-400 text-gray-600 cursor-not-allowed"
                  : areAllImagesCaptured() && isDataSent
                  ? "bg-blue-500 text-white"
                  : "bg-gray-400 text-gray-600 cursor-not-allowed"
              }`}
              disabled={!areAllImagesCaptured() || isAnalyzeComplete} // Disable if conditions are not met
              title={
                isAnalyzeComplete
                  ? "Analysis is already complete!"
                  : "Click to start the analysis"
              }
            >
              {isAnalyzeComplete ? "Analyzed" : "Analyze"}
            </button>

            <button
              onClick={() => setIsCompletePopupOpen(true)}
              className="px-4 py-2 text-white bg-purple-500 rounded-md shadow-md"
              disabled={!isAnalyzeComplete}
            >
              Complete VKYC
            </button>
          </div>
        </div>
      </div>

      {/* Cropper Modal */}
      {isCropping && cropData && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl max-h-[95vh] flex flex-col items-center justify-center space-y-4">
            {/* Title */}
            <h3 className="text-lg font-semibold text-center">
              Crop {cropType}
            </h3>

            {/* Cropper Section */}
            <div className="relative w-full max-w-md aspect-video">
              <Cropper
                image={cropData}
                crop={crop}
                zoom={zoom}
                aspect={16 / 9}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={(croppedArea, croppedAreaPixels) => {
                  setCropArea(croppedAreaPixels);
                  generateLivePreview(cropData, croppedAreaPixels);
                }}
              />
            </div>

            {/* Cropped Preview */}
            {cropArea && (
              <div className="w-full flex flex-col items-center space-y-2">
                <h4 className="text-sm font-semibold">Cropped Preview:</h4>
                <img
                  src={livePreview}
                  alt="Cropped Preview"
                  className="border rounded shadow-md max-w-xs object-contain"
                />
              </div>
            )}

            {/* Action Buttons */}
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setIsCropping(false)}
                className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={saveCroppedImage}
                className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Analyze Popup */}
      <AnalyzePopup
        isOpen={isAnalyzePopupOpen}
        onClose={() => setIsAnalyzePopupOpen(false)}
        customerData={customerData}
        analyzedStates={analyzedStates}
        handleAnalyze={handleAnalyze}
        handleAccept={handleAccept}
        setIsAnalyzeComplete={setIsAnalyzeComplete} // Pass the setter as a prop
        handleAnalyzeService={handleAnalyzeService}
        handleReject={handleReject}
        documents={documents}
        leadId={leadId}
        croppedImages={{
          "Aadhaar Photo": capturedImages.aadhaar_photo,
          "Pan Photo": capturedImages.pan_photo,
          "Customer Photo": capturedImages.customer_photo,
          "Signature Photo": capturedImages.signature_photo,
        }}
      />

      {/* Remark Modal */}
      {showRemarkModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h3 className="text-lg font-semibold mb-4">Reject Document</h3>
            <textarea
              value={rejectRemark}
              onChange={(e) => setRejectRemark(e.target.value)}
              placeholder="Enter remark for rejection"
              className="w-full p-2 border rounded-md"
            ></textarea>
            <div className="mt-4 flex justify-end space-x-4">
              <button
                onClick={() => setShowRemarkModal(false)}
                className="px-4 py-2 bg-gray-500 text-white rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={() => submitReject(currentRejectDoc)} // Pass the current document label
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Complete VKYC Popup */}
      {isCompletePopupOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative bg-white p-6 rounded-lg shadow-lg w-96">
            <h3 className="text-lg font-semibold mb-4">Complete VKYC</h3>
            <div className="space-y-4">
              <textarea
                value={reviewStatus}
                onChange={(e) => setReviewStatus(e.target.value)}
                placeholder="Enter your review"
                className="w-full p-2 border rounded-md"
              ></textarea>
              <select
                value={approvalStatus}
                onChange={(e) => setApprovalStatus(e.target.value)}
                className="w-full p-2 border rounded-md"
              >
                <option value="">Select Approval Status</option>
                <option value="Approved">Approve</option>
                <option value="Rejected">Reject</option>
              </select>
            </div>
            <div className="mt-4 flex justify-end space-x-4">
              <button
                onClick={() => setIsCompletePopupOpen(false)}
                className="px-4 py-2 text-white bg-gray-500 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleCompleteKYC}
                className="px-4 py-2 text-white bg-green-500 rounded-md"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentMeetPage;
