import React, { useState } from "react";
import { FaCheckCircle, FaTimesCircle, FaSearch } from "react-icons/fa";

const AnalyzePopup = ({
    isOpen,
    onClose,
    analyzedStates,
    handleAnalyzeService,
    setIsAnalyzeComplete,
    handleAccept,
    handleReject,
    documents,
    leadId,
    croppedImages, // Pass cropped images as props
}) => {
    const [scores, setScores] = useState({}); // Track scores for analyzed documents
    const [reasons, setReasons] = useState({}); // Track rejection reasons

    if (!isOpen) return null;

    const handleAnalyzeClick = async (docLabel, uploadedDoc, capturedDoc) => {
        if (scores[docLabel]) {
          console.warn(`Document "${docLabel}" has already been analyzed.`);
          return;
        }
      
        try {
          const response = await handleAnalyzeService(docLabel, uploadedDoc, capturedDoc); // Call the service
        
          setScores((prev) => ({
            ...prev,
            [docLabel]: response.score, // Save the score for the document
          }));
        } catch (error) {
          console.error("Error analyzing document:", error);
        }
      };
      
    if (!documents || documents.length === 0) {
        console.error("Documents data is missing.");
        return null;
    }

    const handleReasonChange = (docLabel, reason) => {
        setReasons((prev) => ({
            ...prev,
            [docLabel]: reason,
        }));
    };

    const handleSubmitScores = async () => {
        // Prepare payload
        const payload = {
            lead_id: leadId, // Assuming `customerData` contains the lead_id
            customer_doc_score: String(scores["Customer Photo"] || 0),
            customer_doc_status: analyzedStates["Customer Photo"] === "Accepted" ? 1 : 0,
            customer_doc_reason: reasons["Customer Photo"] || "Reason not provided",
            pan_doc_score: String(scores["Pan Photo"] || 0),
            pan_doc_status: analyzedStates["Pan Photo"] === "Accepted" ? 1 : 0,
            pan_doc_reason: reasons["Pan Photo"] || "Reason not provided",
            signature_doc_score: String(scores["Signature Photo"] || 0),
            signature_doc_status: analyzedStates["Signature Photo"] === "Accepted" ? 1 : 0,
            signature_doc_reason: reasons["Signature Photo"] || "Reason not provided",
            aadhaar_doc_score: String(scores["Aadhaar Photo"] || 0),
            aadhaar_doc_status: analyzedStates["Aadhaar Photo"] === "Accepted" ? 1 : 0,
            aadhaar_doc_reason: reasons["Aadhaar Photo"] || "Reason not provided",
        };

        console.log("Payload to be sent:", payload);

        // API call
        try {
            const accessToken = sessionStorage.getItem("accessToken");
            const response = await fetch("https://vkyc-back.prepstripe.com/api/store-analysis-result", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Failed to store analysis results");
            }

            const responseData = await response.json();
            console.log("API Response:", responseData);

            alert(responseData.message || "Scores submitted successfully!");
            setIsAnalyzeComplete(true);
            // Close the popup after success
            onClose();
                } catch (error) {
            console.error("Error submitting scores:", error);
            alert("Failed to submit scores. Please try again.");
        }
    };


    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
            <div className="relative bg-white p-6 rounded-lg w-[95%] max-w-6xl overflow-auto max-h-[90vh]">
                <h3 className="text-xl font-semibold mb-6 text-center">Analyze Documents</h3>
                {/* Header Row */}
                <div className="grid grid-cols-4 gap-4 mb-4 font-semibold text-center">
                    <div>Document</div>
                    <div>Uploaded</div>
                    <div>Captured</div>
                    <div>Status</div>
                </div>
                {/* Document Rows */}
                {documents[0].data.map((doc, index) => {
                    const uploadedDoc = doc.url; // Get the uploaded image URL
                    const capturedDoc = croppedImages[doc.label] || null; // Get the cropped image from local storage
                    const analyzedStatus = analyzedStates[doc.label]; // Get the status of the document (Analyzed, Accepted, or Rejected)

                    return (
                        <div key={index} className="grid grid-cols-4 gap-4 items-center mb-4">
                            {/* Document Name */}
                            <div className="text-center font-medium">{doc.label}</div>

                            {/* Uploaded Image */}
                            <div className="flex justify-center items-center">
                                {uploadedDoc ? (
                                    <img
                                        src={uploadedDoc}
                                        alt={`Uploaded ${doc.label}`}
                                        className="w-24 h-24 object-cover"
                                    />
                                ) : (
                                    <div className="w-24 h-24 bg-gray-200 flex items-center justify-center text-gray-600 text-sm">
                                        No Image
                                    </div>
                                )}
                            </div>

                            {/* Captured Image */}
                            <div className="flex justify-center items-center">
                                {capturedDoc ? (
                                    <img
                                        src={capturedDoc}
                                        alt={`Captured ${doc.label}`}
                                        className="w-24 h-24 object-cover"
                                    />
                                ) : (
                                    <div className="w-24 h-24 bg-gray-200 flex items-center justify-center text-gray-600 text-sm">
                                        No Image
                                    </div>
                                )}
                            </div>

                            {/* Actions / Status */}
                            <div className="flex flex-col justify-center items-center space-y-2">
                                {scores[doc.label] && (
                                    <div className="text-blue-500 font-semibold">
                                        Score: {scores[doc.label]}
                                    </div>
                                )}
                                {scores[doc.label] ? (
                                    analyzedStatus === "Accepted" ? (
                                        <div className="text-green-500 font-semibold flex items-center">
                                            <FaCheckCircle className="mr-2" /> Accepted
                                        </div>
                                    ) : analyzedStatus === "Rejected" ? (
                                        <div className="flex flex-col items-center">
                                            <div className="text-red-500 font-semibold flex items-center">
                                                <FaTimesCircle className="mr-2" /> Rejected
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex space-x-4">
                                            {/* Accept Icon */}
                                            <FaCheckCircle
                                                className="text-green-500 text-xl cursor-pointer"
                                                title="Accept"
                                                onClick={() => handleAccept(doc.label)}
                                            />
                                            {/* Reject Icon */}
                                            <FaTimesCircle
                                                className="text-red-500 text-xl cursor-pointer"
                                                title="Reject"
                                                onClick={() => handleReject(doc.label)}
                                            />
                                        </div>
                                    )
                                ) : (
                                    <button
                                        onClick={() =>
                                            handleAnalyzeClick(doc.label, uploadedDoc, capturedDoc)
                                        }
                                        className="px-4 py-2 bg-blue-500 text-white rounded-md text-sm flex items-center"
                                    >
                                        <FaSearch className="mr-2" /> Analyze
                                    </button>
                                )}
                            </div>
                        </div>
                    );
                })}
                {/* Submit Scores Button */}
                <div className="mt-6 flex justify-end">
                    <button
                        onClick={handleSubmitScores}
                        className="px-6 py-3 bg-green-500 text-white rounded-md font-semibold shadow-lg"
                    >
                        Submit Scores
                    </button>
                </div>
                {/* Close Button */}
                <div className="mt-4 flex justify-end">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-500 text-white rounded-md"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AnalyzePopup;
